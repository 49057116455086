import React from "react";
import HTMLContent from "./HTMLContent";
import Icon from "./Icon";

export default function FAQ({ question, answer }) {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen((current) => !current);
  };
  return (
    <li className="faq">
      <button onClick={handleClick}>
        <span>{question}</span>
        <span
          className="chevron"
          style={{ transform: `rotate(${open ? 180 : 0}deg)` }}
        >
          <Icon iconName="chevronDown" width={16} />
        </span>
      </button>
      {open && (
        <div className="answer">
          <HTMLContent content={answer} />
        </div>
      )}
    </li>
  );
}
