import React, { Fragment } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import HTMLContent from "../components/HTMLContent";
import PageBanner from "../components/PageBanner";
import Icon from "../components/Icon";
import FAQ from "../components/FAQ";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const convertPhoneNumberToTelLink = (phoneNumber) => {
  return "tel:+1" + phoneNumber.replace(/\D/g, "");
};

const reasonIcons = {
  Cap: "cap",
  Award: "award",
  "Heart Hands": "heartHands",
  "Badge Dollar": "badgeDollar",
};

const colorMap = {
  "0": "#00396B",
  "1": "#0086B3",
  "2": "#5AA79D",
};

export const ScholarshipEndowmentPageTemplate = (props) => {
  const {
    banner,
    metricsSection,
    overviewSection,
    OverviewDescription,
    contributionSection,
    generalScholarshipSection,
    GeneralScholarshipDescription,
    namedScholarshipSection,
    NamedScholarshipDescription,
    donorSection,
    faqs,
  } = props;

  return (
    <>
      <PageBanner>
        <div className="width-container">
          <h1 className="page-banner-header smaller-mobile-header">
            SCHOLARSHIP ENDOWMENT
          </h1>
          <p>{banner.description}</p>
          <div
            style={{
              maxWidth: 180,
              background: "white",
              borderRadius: 8,
              padding: 8,
              margin: "32px auto 0px",
            }}
          >
            <PreviewCompatibleImage
              imageData={banner.image}
              gatsbyImageProps={{ fadeIn: false }}
            />
          </div>
        </div>
      </PageBanner>
      <div className="layout-container">
        <div className="top-image-container alt-banner">
          <div className="endowment-metrics-header">
            <span>KEY METRICS</span>
          </div>
          <div className="grid-container endowment-metrics">
            {metricsSection.metrics.map(({ metric, description }, index) => {
              return (
                <div className="grid-item-3" key={metric}>
                  <h2 style={{ color: colorMap[index] }}>{metric}</h2>
                  <p>{description}</p>
                </div>
              );
            })}
          </div>
        </div>
        <section className="body-padding">
          <div className="landing-header-container text-center">
            <h3>{overviewSection.heading}</h3>
          </div>
          <div className="grid-container add-width">
            <div className="grid-item grid-item-2">
              <div>{OverviewDescription}</div>
              {overviewSection.quotes.map(({ quote, name }, index) => {
                return (
                  <div className="endowment-quote" key={`quote-${index}`}>
                    <span className="icon">
                      <Icon iconName="quote" width={16} />
                    </span>
                    <p>
                      <em>{quote}</em>{" "}
                      <span className="block mt-1">
                        <strong>- {name}</strong>
                      </span>
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="grid-item grid-item-2">
              <div className="body-img">
                <PreviewCompatibleImage
                  imageData={overviewSection.image}
                  gatsbyImageProps={{ fadeIn: false }}
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <h6 className="text-center width-container">
            {contributionSection.heading}
          </h6>
          <div className="grid-container add-width endowment-reasons-section">
            {contributionSection.reasons.map(({ message, icon }) => {
              return (
                <div key={icon} className="grid-item-4-alt add-padding">
                  <span className="icon">
                    <Icon iconName={reasonIcons[icon]} width={40} />
                  </span>
                  <p>{message}</p>
                </div>
              );
            })}
          </div>
        </section>
        <section className="body-padding">
          <div className="grid-container add-width">
            <div className="grid-item grid-item-2">
              <h3>{generalScholarshipSection.heading}</h3>
              <div>{GeneralScholarshipDescription}</div>
              <div className="btn-container">
                <Link to="/donate/" className="btn primary-btn full-width-sm">
                  MAKE A GIFT
                </Link>
              </div>
            </div>
            <div className="grid-item grid-item-2">
              <div className="body-img">
                <PreviewCompatibleImage
                  imageData={generalScholarshipSection.image}
                  gatsbyImageProps={{ fadeIn: false }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="body-padding">
          <div className="grid-container add-width row-reverse-section">
            <div className="grid-item grid-item-2">
              <h3>{namedScholarshipSection.heading}</h3>
              <div>{NamedScholarshipDescription}</div>
              <p>
                <span className="block">
                  For more information, contact{" "}
                  {namedScholarshipSection.contactInfo.name}:
                </span>
                <span className="block mt-1">
                  <a
                    href={convertPhoneNumberToTelLink(
                      namedScholarshipSection.contactInfo.phone
                    )}
                  >
                    {namedScholarshipSection.contactInfo.phone}
                  </a>
                </span>
                <span className="block">
                  <a
                    href={`mailto:${namedScholarshipSection.contactInfo.email}`}
                  >
                    {namedScholarshipSection.contactInfo.email}
                  </a>
                </span>
              </p>
            </div>
            <div className="grid-item grid-item-2">
              <div className="body-img">
                <PreviewCompatibleImage
                  imageData={namedScholarshipSection.image}
                  gatsbyImageProps={{ fadeIn: false }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="body-padding">
          <div className="landing-header-container text-center">
            <h3>{donorSection.heading}</h3>
          </div>
          <div className="grid-container add-width">
            <div className="grid-item grid-item-2 endowment-donor-list">
              {donorSection.donorLists.map((donorList) => {
                return (
                  <Fragment key={donorList.title}>
                    <h5>{donorList.title}</h5>
                    {donorList.donors && donorList.donors[0] && (
                      <ul>
                        {donorList.donors.map((donor) => (
                          <li key={donor}>{donor}</li>
                        ))}
                      </ul>
                    )}
                  </Fragment>
                );
              })}
            </div>
            <div className="grid-item grid-item-2">
              {donorSection.quotes.map(({ quote, name }, index) => {
                return (
                  <div className="endowment-quote" key={`quote-${index}`}>
                    <span className="icon">
                      <Icon iconName="quote" width={16} />
                    </span>
                    <p>
                      <em>{quote}</em>{" "}
                      <span className="block mt-1">
                        <strong>- {name}</strong>
                      </span>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <section className="body-padding">
          <div className="landing-header-container text-center">
            <h3>Endowment FAQs</h3>
          </div>
          <ul className="padding-bottom-2">
            {faqs.map(({ question, answer }) => {
              return <FAQ question={question} answer={answer} key={question} />;
            })}
          </ul>
          <div className="bottom-btn-container text-center">
            <Link to="/donate/" className="btn primary-btn full-width-sm">
              MAKE A GIFT TODAY
            </Link>
          </div>
        </section>
      </div>
    </>
  );
};

const ScholarshipEndowmentPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        banner,
        metricsSection,
        overviewSection,
        contributionSection,
        generalScholarshipSection,
        namedScholarshipSection,
        donorSection,
      },
    },
  } = data;
  return (
    <Layout
      pageTitle={`Scholarship Endowment`}
      ogUrl="/scholarship-endowment/"
      pageDecription="The Philips Academy Scholarship Endowment plays a critical role in allowing the school to fulfill its mission and ensure long-term financial stability. Endowment funds provide scholarships to students who meet criteria for need-based assistance."
    >
      <ScholarshipEndowmentPageTemplate
        banner={banner}
        metricsSection={metricsSection}
        overviewSection={overviewSection}
        OverviewDescription={
          <HTMLContent
            content={
              data.markdownRemark.fields.frontmatterMarkdownHTML.overviewSection
                .markdown
            }
          />
        }
        contributionSection={contributionSection}
        generalScholarshipSection={generalScholarshipSection}
        GeneralScholarshipDescription={
          <HTMLContent
            content={
              data.markdownRemark.fields.frontmatterMarkdownHTML
                .generalScholarshipSection.markdown
            }
          />
        }
        namedScholarshipSection={namedScholarshipSection}
        NamedScholarshipDescription={
          <HTMLContent
            content={
              data.markdownRemark.fields.frontmatterMarkdownHTML
                .namedScholarshipSection.markdown
            }
          />
        }
        donorSection={donorSection}
        faqs={data.markdownRemark.fields.faqHTML}
      />
    </Layout>
  );
};

export default ScholarshipEndowmentPage;

export const ScholarshipEndowmentPageQuery = graphql`
  query ScholarshipEndowmentPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        banner {
          description
          image {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        metricsSection {
          metrics {
            metric
            description
          }
        }
        overviewSection {
          heading
          image {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          quotes {
            quote
            name
          }
        }
        contributionSection {
          heading
          reasons {
            icon
            message
          }
        }
        generalScholarshipSection {
          heading
          image {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        namedScholarshipSection {
          heading
          image {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          contactInfo {
            name
            phone
            email
          }
        }
        donorSection {
          heading
          donorLists {
            title
            donors
          }
          quotes {
            quote
            name
          }
        }
      }
      fields {
        frontmatterMarkdownHTML {
          overviewSection {
            markdown
          }
          generalScholarshipSection {
            markdown
          }
          namedScholarshipSection {
            markdown
          }
        }
        faqHTML {
          question
          answer
        }
      }
    }
  }
`;
